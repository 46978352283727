<template>
  <div class="p-3">
    <p class="fs-24 fw-500">Patient States</p>
    <div v-if="changedCheckpoint" class="px-3">
      <div class="row">
        <div class="col-4">
          <p class="fs-18 fw-500 text-center border-bottom">Checkpoints</p>
        </div>
        <div class="col-4">
          <p class="fs-18 fw-500 text-center border-bottom">Checklists</p>
        </div>
        <div class="col-4">
          <p class="fs-18 fw-500 text-center border-bottom">Suggestion of Checklist Items</p>
        </div>
      </div>
      <div
        class="row border-bottom py-2"
        v-for="(cp, i) in changedCheckpoint"
        :key="cp.id"
      >
        <!-- checkpoint -->
        <div class="col-4 border-right">
          <div class="px-2">
          <div
            class="d-flex justify-content-between align-items-center"
            v-if="editting_checkpoint.id !== cp.id"
          >
            <p class="fs-18 fw-700 mb-0">
              <span class="mr-2">{{ cp.name }}</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="cursor-pointer"
                @click="
                  () => {
                    editting_checkpoint = {
                      id: cp.id,
                      status: true,
                      name: cp.name,
                      current: cp,
                    };
                  }
                "
              >
                <path
                  d="M5 17.9999H9.24C9.37161 18.0007 9.50207 17.9755 9.62391 17.9257C9.74574 17.8759 9.85656 17.8026 9.95 17.7099L16.87 10.7799L19.71 7.99994C19.8037 7.90698 19.8781 7.79637 19.9289 7.67452C19.9797 7.55266 20.0058 7.42195 20.0058 7.28994C20.0058 7.15793 19.9797 7.02722 19.9289 6.90536C19.8781 6.7835 19.8037 6.6729 19.71 6.57994L15.47 2.28994C15.377 2.19621 15.2664 2.12182 15.1446 2.07105C15.0227 2.02028 14.892 1.99414 14.76 1.99414C14.628 1.99414 14.4973 2.02028 14.3754 2.07105C14.2536 2.12182 14.143 2.19621 14.05 2.28994L11.23 5.11994L4.29 12.0499C4.19732 12.1434 4.12399 12.2542 4.07423 12.376C4.02446 12.4979 3.99924 12.6283 4 12.7599V16.9999C4 17.2652 4.10536 17.5195 4.29289 17.707C4.48043 17.8946 4.73478 17.9999 5 17.9999ZM14.76 4.40994L17.59 7.23994L16.17 8.65994L13.34 5.82994L14.76 4.40994ZM6 13.1699L11.93 7.23994L14.76 10.0699L8.83 15.9999H6V13.1699ZM21 19.9999H3C2.73478 19.9999 2.48043 20.1053 2.29289 20.2928C2.10536 20.4804 2 20.7347 2 20.9999C2 21.2652 2.10536 21.5195 2.29289 21.707C2.48043 21.8946 2.73478 21.9999 3 21.9999H21C21.2652 21.9999 21.5196 21.8946 21.7071 21.707C21.8946 21.5195 22 21.2652 22 20.9999C22 20.7347 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 19.9999 21 19.9999Z"
                  fill="#20409B"
                />
              </svg>
            </p>
            
            <div>
              <div
                class="cursor-pointer switch-index"
                :class="i > 0 && !changing_index ? '' : 'disabled'"
                @click="switchIndexCheckpoint(cp, i, 'up')"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.2099 15.9998H7.78995C7.45753 16.0003 7.13178 15.9066 6.85039 15.7297C6.56901 15.5527 6.34349 15.2997 6.19995 14.9998C6.03191 14.6441 5.9672 14.2483 6.01316 13.8576C6.05913 13.4668 6.21394 13.0969 6.45995 12.7898L10.6699 7.68983C10.8352 7.4992 11.0394 7.34631 11.2689 7.24153C11.4984 7.13675 11.7477 7.08252 11.9999 7.08252C12.2522 7.08252 12.5015 7.13675 12.731 7.24153C12.9605 7.34631 13.1647 7.4992 13.3299 7.68983L17.5399 12.7898C17.786 13.0969 17.9408 13.4668 17.9867 13.8576C18.0327 14.2483 17.968 14.6441 17.7999 14.9998C17.6564 15.2997 17.4309 15.5527 17.1495 15.7297C16.8681 15.9066 16.5424 16.0003 16.2099 15.9998Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div
                class="cursor-pointer switch-index"
                :class="
                  i < changedCheckpoint.length - 1 && !changing_index
                    ? ''
                    : 'disabled'
                "
                @click="switchIndexCheckpoint(cp, i, 'down')"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9999 17C11.7447 16.9987 11.4929 16.9406 11.2628 16.8299C11.0328 16.7192 10.8302 16.5587 10.6699 16.36L6.45995 11.26C6.21394 10.953 6.05913 10.583 6.01316 10.1923C5.9672 9.80153 6.03191 9.40574 6.19995 9.05C6.33623 8.74083 6.55862 8.47741 6.84057 8.29122C7.12251 8.10503 7.45209 8.00393 7.78995 8H16.2099C16.5478 8.00393 16.8774 8.10503 17.1593 8.29122C17.4413 8.47741 17.6637 8.74083 17.7999 9.05C17.968 9.40574 18.0327 9.80153 17.9867 10.1923C17.9408 10.583 17.786 10.953 17.5399 11.26L13.3299 16.36C13.1696 16.5587 12.9671 16.7192 12.7371 16.8299C12.507 16.9406 12.2552 16.9987 11.9999 17Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div
            class="d-flex align-items-center mb-2"
            v-if="editting_checkpoint.id === cp.id"
          >
            <input
              type="text"
              class="form-control border"
              placeholder="Tên"
              autocomplete="off"
              v-model="editting_checkpoint.name"
            />
            <a
              class="cursor-pointer mx-2"
              href="javascript:;"
              :disabled="!editting_checkpoint.name"
              @click="updateCheckpoints(cp)"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 6L9 17L4 12"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
            <a
              class="cursor-pointer mx-2"
              href="javascript:;"
              @click="
                () => {
                  editting_checkpoint = {
                    id: 0,
                    status: false,
                    name: '',
                    current: null,
                  };
                }
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z"
                  fill="black"
                />
              </svg>
            </a>
          </div>
        </div>
        </div>
        <!-- checkpoint -->

        <div class="col-8">
          <div
            v-for="(cl, j) in cp.checklists"
            :key="'cl' + cl.id"
            class="p-2 border-bottom"
          >
            <div class="row">
              <div class="col-6 cl-items border-right">
                <div
                  class="d-flex justify-content-between align-items-center"
                  v-if="editting_checklist.id !== cl.id"
                >
                  <p class="fs-16 fw-500 mb-0">
                    <span class="mr-2">
                      {{ cl.name }}
                    </span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer"
                      @click="
                        () => {
                          editting_checklist = {
                            id: cl.id,
                            status: true,
                            name: cl.name,
                            current: cl,
                          };
                        }
                      "
                    >
                      <path
                        d="M5 17.9999H9.24C9.37161 18.0007 9.50207 17.9755 9.62391 17.9257C9.74574 17.8759 9.85656 17.8026 9.95 17.7099L16.87 10.7799L19.71 7.99994C19.8037 7.90698 19.8781 7.79637 19.9289 7.67452C19.9797 7.55266 20.0058 7.42195 20.0058 7.28994C20.0058 7.15793 19.9797 7.02722 19.9289 6.90536C19.8781 6.7835 19.8037 6.6729 19.71 6.57994L15.47 2.28994C15.377 2.19621 15.2664 2.12182 15.1446 2.07105C15.0227 2.02028 14.892 1.99414 14.76 1.99414C14.628 1.99414 14.4973 2.02028 14.3754 2.07105C14.2536 2.12182 14.143 2.19621 14.05 2.28994L11.23 5.11994L4.29 12.0499C4.19732 12.1434 4.12399 12.2542 4.07423 12.376C4.02446 12.4979 3.99924 12.6283 4 12.7599V16.9999C4 17.2652 4.10536 17.5195 4.29289 17.707C4.48043 17.8946 4.73478 17.9999 5 17.9999ZM14.76 4.40994L17.59 7.23994L16.17 8.65994L13.34 5.82994L14.76 4.40994ZM6 13.1699L11.93 7.23994L14.76 10.0699L8.83 15.9999H6V13.1699ZM21 19.9999H3C2.73478 19.9999 2.48043 20.1053 2.29289 20.2928C2.10536 20.4804 2 20.7347 2 20.9999C2 21.2652 2.10536 21.5195 2.29289 21.707C2.48043 21.8946 2.73478 21.9999 3 21.9999H21C21.2652 21.9999 21.5196 21.8946 21.7071 21.707C21.8946 21.5195 22 21.2652 22 20.9999C22 20.7347 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 19.9999 21 19.9999Z"
                        fill="#20409B"
                      />
                    </svg>
                  </p>
                  <div>
                    <div
                      class="cursor-pointer switch-index"
                      :class="j > 0 && !changing_index ? '' : 'disabled'"
                      @click="switchIndexChecklist(i, j, 'up')"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.2099 15.9998H7.78995C7.45753 16.0003 7.13178 15.9066 6.85039 15.7297C6.56901 15.5527 6.34349 15.2997 6.19995 14.9998C6.03191 14.6441 5.9672 14.2483 6.01316 13.8576C6.05913 13.4668 6.21394 13.0969 6.45995 12.7898L10.6699 7.68983C10.8352 7.4992 11.0394 7.34631 11.2689 7.24153C11.4984 7.13675 11.7477 7.08252 11.9999 7.08252C12.2522 7.08252 12.5015 7.13675 12.731 7.24153C12.9605 7.34631 13.1647 7.4992 13.3299 7.68983L17.5399 12.7898C17.786 13.0969 17.9408 13.4668 17.9867 13.8576C18.0327 14.2483 17.968 14.6441 17.7999 14.9998C17.6564 15.2997 17.4309 15.5527 17.1495 15.7297C16.8681 15.9066 16.5424 16.0003 16.2099 15.9998Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div
                      class="cursor-pointer switch-index"
                      :class="
                        j < cp.checklists.length - 1 && !changing_index
                          ? ''
                          : 'disabled'
                      "
                      @click="switchIndexChecklist(i, j, 'down')"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9999 17C11.7447 16.9987 11.4929 16.9406 11.2628 16.8299C11.0328 16.7192 10.8302 16.5587 10.6699 16.36L6.45995 11.26C6.21394 10.953 6.05913 10.583 6.01316 10.1923C5.9672 9.80153 6.03191 9.40574 6.19995 9.05C6.33623 8.74083 6.55862 8.47741 6.84057 8.29122C7.12251 8.10503 7.45209 8.00393 7.78995 8H16.2099C16.5478 8.00393 16.8774 8.10503 17.1593 8.29122C17.4413 8.47741 17.6637 8.74083 17.7999 9.05C17.968 9.40574 18.0327 9.80153 17.9867 10.1923C17.9408 10.583 17.786 10.953 17.5399 11.26L13.3299 16.36C13.1696 16.5587 12.9671 16.7192 12.7371 16.8299C12.507 16.9406 12.2552 16.9987 11.9999 17Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex align-items-center mb-2"
                  v-if="editting_checklist.id === cl.id"
                >
                  <input
                    type="text"
                    class="form-control border"
                    placeholder="Tên"
                    autocomplete="off"
                    v-model="editting_checklist.name"
                  />
                  <a
                    class="cursor-pointer mx-2"
                    href="javascript:;"
                    :disabled="!editting_checklist.name"
                    @click="updateChecklists(cl)"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 6L9 17L4 12"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                  <a
                    class="cursor-pointer mx-2"
                    href="javascript:;"
                    @click="
                      () => {
                        editting_checklist = {
                          id: 0,
                          status: false,
                          name: '',
                          current: null,
                        };
                      }
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z"
                        fill="black"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div class="col-6">
                <div
                  v-for="(stt, k) in cl.items"
                  :key="'stt' + stt.id"
                  class="cl-items p-2 border-bottom"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-if="editting_checklist_item.id !== stt.id"
                  >
                    <p class="fs-16 fw-500 mb-0">
                      <span class="mr-2">
                        {{ stt.name }}
                      </span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="cursor-pointer"
                        @click="
                          () => {
                            editting_checklist_item = {
                              id: stt.id,
                              status: true,
                              name: stt.name,
                              current: stt,
                            };
                          }
                        "
                      >
                        <path
                          d="M5 17.9999H9.24C9.37161 18.0007 9.50207 17.9755 9.62391 17.9257C9.74574 17.8759 9.85656 17.8026 9.95 17.7099L16.87 10.7799L19.71 7.99994C19.8037 7.90698 19.8781 7.79637 19.9289 7.67452C19.9797 7.55266 20.0058 7.42195 20.0058 7.28994C20.0058 7.15793 19.9797 7.02722 19.9289 6.90536C19.8781 6.7835 19.8037 6.6729 19.71 6.57994L15.47 2.28994C15.377 2.19621 15.2664 2.12182 15.1446 2.07105C15.0227 2.02028 14.892 1.99414 14.76 1.99414C14.628 1.99414 14.4973 2.02028 14.3754 2.07105C14.2536 2.12182 14.143 2.19621 14.05 2.28994L11.23 5.11994L4.29 12.0499C4.19732 12.1434 4.12399 12.2542 4.07423 12.376C4.02446 12.4979 3.99924 12.6283 4 12.7599V16.9999C4 17.2652 4.10536 17.5195 4.29289 17.707C4.48043 17.8946 4.73478 17.9999 5 17.9999ZM14.76 4.40994L17.59 7.23994L16.17 8.65994L13.34 5.82994L14.76 4.40994ZM6 13.1699L11.93 7.23994L14.76 10.0699L8.83 15.9999H6V13.1699ZM21 19.9999H3C2.73478 19.9999 2.48043 20.1053 2.29289 20.2928C2.10536 20.4804 2 20.7347 2 20.9999C2 21.2652 2.10536 21.5195 2.29289 21.707C2.48043 21.8946 2.73478 21.9999 3 21.9999H21C21.2652 21.9999 21.5196 21.8946 21.7071 21.707C21.8946 21.5195 22 21.2652 22 20.9999C22 20.7347 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 19.9999 21 19.9999Z"
                          fill="#20409B"
                        />
                      </svg>
                    </p>
                    <div>
                      <div
                        class="cursor-pointer switch-index"
                        :class="k > 0 && !changing_index ? '' : 'disabled'"
                        @click="switchIndexChecklistItem(cl, k, 'up')"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.2099 15.9998H7.78995C7.45753 16.0003 7.13178 15.9066 6.85039 15.7297C6.56901 15.5527 6.34349 15.2997 6.19995 14.9998C6.03191 14.6441 5.9672 14.2483 6.01316 13.8576C6.05913 13.4668 6.21394 13.0969 6.45995 12.7898L10.6699 7.68983C10.8352 7.4992 11.0394 7.34631 11.2689 7.24153C11.4984 7.13675 11.7477 7.08252 11.9999 7.08252C12.2522 7.08252 12.5015 7.13675 12.731 7.24153C12.9605 7.34631 13.1647 7.4992 13.3299 7.68983L17.5399 12.7898C17.786 13.0969 17.9408 13.4668 17.9867 13.8576C18.0327 14.2483 17.968 14.6441 17.7999 14.9998C17.6564 15.2997 17.4309 15.5527 17.1495 15.7297C16.8681 15.9066 16.5424 16.0003 16.2099 15.9998Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div
                        class="cursor-pointer switch-index"
                        :class="
                          k < cl.items.length - 1 && !changing_index
                            ? ''
                            : 'disabled'
                        "
                        @click="switchIndexChecklistItem(cl, k, 'down')"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.9999 17C11.7447 16.9987 11.4929 16.9406 11.2628 16.8299C11.0328 16.7192 10.8302 16.5587 10.6699 16.36L6.45995 11.26C6.21394 10.953 6.05913 10.583 6.01316 10.1923C5.9672 9.80153 6.03191 9.40574 6.19995 9.05C6.33623 8.74083 6.55862 8.47741 6.84057 8.29122C7.12251 8.10503 7.45209 8.00393 7.78995 8H16.2099C16.5478 8.00393 16.8774 8.10503 17.1593 8.29122C17.4413 8.47741 17.6637 8.74083 17.7999 9.05C17.968 9.40574 18.0327 9.80153 17.9867 10.1923C17.9408 10.583 17.786 10.953 17.5399 11.26L13.3299 16.36C13.1696 16.5587 12.9671 16.7192 12.7371 16.8299C12.507 16.9406 12.2552 16.9987 11.9999 17Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div
                    class="d-flex align-items-center mb-2"
                    v-if="editting_checklist_item.id === stt.id"
                  >
                    <input
                      type="text"
                      class="form-control border"
                      placeholder="Tên"
                      autocomplete="off"
                      v-model="editting_checklist_item.name"
                    />
                    <a
                      class="cursor-pointer mx-2"
                      href="javascript:;"
                      :disabled="!editting_checklist_item.name"
                      @click="updateChecklistItem(stt)"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                    <a
                      class="cursor-pointer mx-2"
                      href="javascript:;"
                      @click="
                        () => {
                          editting_checklist_item = {
                            id: 0,
                            status: false,
                            name: '',
                            current: null,
                          };
                        }
                      "
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z"
                          fill="black"
                        />
                      </svg>
                    </a>
                  </div>
                </div>

                
                <!-- them moi check status -->

                <div class="p-2">
                  <a
                    href="javascript:;"
                    class="fs-16 fw-500"
                    @click="onOpenCreateChecklistItem(cl)"
                    :class="
                      !creating_checklist.status &&
                      !editting_checkpoint.status &&
                      !editting_checklist.status &&
                      !creating_checkpoint.status &&
                      !editting_checklist_item.status &&
                      !creating_checklist_item.status
                        ? ''
                        : 'disabled'
                    "
                    v-show="creating_checklist_item.mr_checklist_id !== cl.id"
                    >+ Thêm</a
                  >
                </div>
                <div
                  class="d-flex align-items-center mb-2"
                  v-if="
                    !creating_checklist.status &&
                    !editting_checkpoint.status &&
                    !editting_checklist.status &&
                    creating_checklist_item.mr_checklist_id === cl.id &&
                    !creating_checkpoint.status &&
                    !editting_checklist_item.status &&
                    creating_checklist_item.status
                  "
                >
                  <input
                    type="text"
                    class="form-control border"
                    placeholder="Tên"
                    autocomplete="off"
                    v-model="creating_checklist_item.name"
                  />
                  <a
                    class="cursor-pointer mx-2"
                    href="javascript:;"
                    :disabled="!creating_checklist_item.name"
                    @click="postChecklistItem(i)"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 6L9 17L4 12"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                  <a
                    class="cursor-pointer mx-2"
                    href="javascript:;"
                    @click="
                      () => {
                        creating_checklist_item = {
                          mr_checklist_id: 0,
                          status: false,
                          name: '',
                        };
                      }
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z"
                        fill="black"
                      />
                    </svg>
                  </a>
                </div>


                <!-- them moi check status -->
              </div>
            </div>
          </div>
          <!-- them moi checklist -->
          <div class="p-2">
            <a
              href="javascript:;"
              class="fs-16 fw-500"
              @click="onOpenCreateChecklist(cp)"
              :class="
                !creating_checklist.status &&
                !editting_checkpoint.status &&
                !editting_checklist.status &&
                !creating_checkpoint.status
                  ? ''
                  : 'disabled'
              "
              v-show="creating_checklist.mr_checkpoint_id !== cp.id"
              >+ Thêm</a
            >
          </div>
          <div
            class="d-flex align-items-center mb-2"
            v-if="
              creating_checklist.status &&
              !editting_checkpoint.status &&
              !editting_checklist.status &&
              creating_checklist.mr_checkpoint_id === cp.id &&
              !creating_checkpoint.status
            "
          >
            <input
              type="text"
              class="form-control border"
              placeholder="Tên"
              autocomplete="off"
              v-model="creating_checklist.name"
            />
            <a
              class="cursor-pointer mx-2"
              href="javascript:;"
              :disabled="!creating_checklist.name"
              @click="postChecklists(i)"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 6L9 17L4 12"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
            <a
              class="cursor-pointer mx-2"
              href="javascript:;"
              @click="
                () => {
                  creating_checklist = {
                    mr_checkpoint_id: 0,
                    status: false,
                    name: '',
                  };
                }
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z"
                  fill="black"
                />
              </svg>
            </a>
          </div>
          <!-- them moi checklist -->
        </div>
      </div>
    </div>
    <div class="row mx-2">
      <div class="col-6">
        <div class="p-2 pl-3">
          <a
            href="javascript:;"
            class="fs-16 fw-500"
            @click="onOpenCreateCheckpoint"
            v-if="
              !creating_checkpoint.status &&
              !editting_checkpoint.status &&
              !editting_checklist.status &&
              !creating_checklist.status
            "
            >+ Thêm</a
          >
        </div>
        <div
          class="d-flex align-items-center"
          v-if="
            creating_checkpoint.status &&
            !editting_checkpoint.status &&
            !editting_checklist.status &&
            !creating_checklist.status
          "
        >
          <input
            type="text"
            class="form-control border"
            placeholder="Tên checkpoint"
            autocomplete="off"
            v-model="creating_checkpoint.name"
          />
          <a
            class="cursor-pointer mx-2"
            href="javascript:;"
            :disabled="!creating_checkpoint.name"
            @click="postCheckpoints"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 6L9 17L4 12"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <a
            class="cursor-pointer mx-2"
            href="javascript:;"
            @click="
              () => {
                creating_checkpoint = {
                  status: false,
                  name: '',
                };
              }
            "
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z"
                fill="black"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PatientState',
  data () {
    return {
      checkpoints: null,
      changedCheckpoint: null,
      loading: false,
      editting_checkpoint: {
        id: 0,
        status: false,
        name: '',
        current: null
      },
      editting_checklist: {
        id: 0,
        status: false,
        name: '',
        current: null
      },
      editting_checklist_item: {
        id: 0,
        status: false,
        name: '',
        current: null
      },
      creating_checklist: {
        mr_checkpoint_id: 0,
        status: false,
        name: ''
      },
      creating_checklist_item: {
        mr_checklist_id: 0,
        status: false,
        name: ''
      },
      creating_checkpoint: {
        status: false,
        name: ''
      },
      changing_index: false
    }
  },
  mounted () {
    this.getCheckpoints()
  },
  methods: {
    async getCheckpoints () {
      let params = {
        limit: 10000,
        sort: 'ordinal_num',
        sort_by: 'asc'
      }
      this.loading = true
      this.$rf.getRequest('AdminRequest').getCheckpoints(params).then((r) => {
        this.checkpoints = r.data
        this.changedCheckpoint = r.data?.data
      }).finally(() => {
        this.loading = false
        this.changing_index = false
      })
    },
    onOpenCreateCheckpoint () {
      this.creating_checkpoint = {
        status: true,
        name: ''
      }

      this.creating_checklist = {
        mr_checkpoint_id: 0,
        status: false,
        name: ''
      }
      this.editting_checkpoint = {
        id: 0,
        status: false,
        name: '',
        current: null
      }
      this.editting_checklist = {
        id: 0,
        status: false,
        name: '',
        current: null
      }
      this.editting_checklist_item = {
        id: 0,
        status: false,
        name: '',
        current: null
      }
      this.creating_checklist_item = {
        mr_checklist_id: 0,
        status: false,
        name: ''
      }
    },
    switchIndexCheckpoint (check, i, type = 'down') {
      if (type === 'up') {
        if (i > 0) {
          let b = this.changedCheckpoint[i]
          this.changedCheckpoint[i] = this.changedCheckpoint[i - 1]
          this.changedCheckpoint[i - 1] = b
        }
      } else {
        if (i < this.changedCheckpoint.length - 1) {
          let b = this.changedCheckpoint[i]
          this.changedCheckpoint[i] = this.changedCheckpoint[i + 1]
          this.changedCheckpoint[i + 1] = b
        }
      }
      this.multipleUpdateCheckpointOrdNum()
    },
    async postCheckpoints () {
      let params = {
        category_name: 'baub',
        name: this.creating_checkpoint.name,
        ordinal_num: this.changedCheckpoint && this.changedCheckpoint.length ? this.changedCheckpoint[this.changedCheckpoint.length - 1].ordinal_num + 1 : 1
      }
      this.loading = true
      this.$rf.getRequest('AdminRequest').postCheckpoints(params).then((r) => {
        this.getCheckpoints()
        this.creating_checkpoint = {
          status: false,
          name: ''
        }
      }).finally(() => {
        this.loading = false
      })
    },
    async updateCheckpoints (cp) {
      let params = {
        category_name: cp.category_name,
        name: this.editting_checkpoint.name,
        ordinal_num: cp.ordinal_num
      }
      this.loading = true
      this.$rf.getRequest('AdminRequest').updateCheckpoints(cp.id, params).then((r) => {
        this.getCheckpoints()
        this.editting_checkpoint = {
          id: 0,
          status: false,
          name: '',
          current: null
        }
      }).finally(() => {
        this.loading = false
      })
    },
    async multipleUpdateCheckpointOrdNum () {
      this.changing_index = true
      let checkpoints = []
      this.changedCheckpoint.forEach((c, i) => {
        checkpoints.push({
          id: c.id,
          category_name: 'baub',
          name: c.name,
          ordinal_num: i + 1
        })
      })
      let r = this.$rf.getRequest('AdminRequest').multipleUpdateCheckpoints({ checkpoints: checkpoints }).finally(() => {
        this.getCheckpoints()
        this.multipleUpdateChecklistOrdNum()
        this.changing_index = false
      })
      return r
    },
    // checklist
    onOpenCreateChecklist (cp) {
      this.creating_checkpoint = {
        status: false,
        name: ''
      }
      this.creating_checklist = {
        mr_checkpoint_id: cp.id,
        status: true,
        name: ''
      }
      this.editting_checkpoint = {
        id: 0,
        status: false,
        name: '',
        current: null
      }
      this.editting_checklist = {
        id: 0,
        status: false,
        name: '',
        current: null
      }
      this.editting_checklist_item = {
        id: 0,
        status: false,
        name: '',
        current: null
      }
      this.creating_checklist_item = {
        mr_checklist_id: 0,
        status: false,
        name: ''
      }
    },
    switchIndexChecklist (i, j, type = 'down') {
      if (type === 'up') {
        if (j > 0) {
          let b = this.changedCheckpoint[i].checklists[j]
          this.changedCheckpoint[i].checklists[j] = this.changedCheckpoint[i].checklists[j - 1]
          this.changedCheckpoint[i].checklists[j - 1] = b
        }
      } else {
        if (j < this.changedCheckpoint[i].checklists.length - 1) {
          let b = this.changedCheckpoint[i].checklists[j]
          this.changedCheckpoint[i].checklists[j] = this.changedCheckpoint[i].checklists[j + 1]
          this.changedCheckpoint[i].checklists[j + 1] = b
        }
      }
      this.multipleUpdateChecklistOrdNum()
    },
    async postChecklists (index) {
      let params = {
        mr_checkpoint_id: this.creating_checklist.mr_checkpoint_id,
        name: this.creating_checklist.name,
        ordinal_num: 255
      }
      this.loading = true
      this.$rf.getRequest('AdminRequest').postChecklists(params).then((r) => {
        this.changedCheckpoint[index].checklists.push(r.data)
        this.multipleUpdateChecklistOrdNum()
        this.creating_checklist = {
          mr_checkpoint_id: 0,
          status: false,
          name: ''
        }
      }).finally(() => {
        this.loading = false
      })
    },
    async updateChecklists (cl) {
      let params = {
        mr_checkpoint_id: cl.mr_checkpoint_id,
        name: this.editting_checklist.name,
        ordinal_num: cl.ordinal_num
      }
      this.changing_index = true
      this.$rf.getRequest('AdminRequest').updateChecklists(cl.id, params).then((r) => {
        this.getCheckpoints()
        this.editting_checklist = {
          id: 0,
          status: false,
          name: '',
          current: null
        }
      }).finally(() => {
        this.changing_index = false
      })
    },
    async multipleUpdateChecklistOrdNum () {
      this.changing_index = true
      let checklists = []
      let i = 0
      this.changedCheckpoint.forEach((c) => {
        c.checklists.forEach((cl) => {
          i++
          checklists.push({
            id: cl.id,
            mr_checkpoint_id: cl.mr_checkpoint_id,
            name: cl.name,
            ordinal_num: i
          })
        })
      })
      let r = this.$rf.getRequest('AdminRequest').multipleUpdateChecklists({ checklists: checklists }).finally(() => {
        this.getCheckpoints()
        this.changing_index = false
      })
      return r
    },
    // checklist

    // checklist item
    onOpenCreateChecklistItem (cl) {
      this.creating_checkpoint = {
        status: false,
        name: ''
      }
      this.creating_checklist = {
        mr_checkpoint_id: 0,
        status: false,
        name: ''
      }
      this.editting_checkpoint = {
        id: 0,
        status: false,
        name: '',
        current: null
      }
      this.editting_checklist = {
        id: 0,
        status: false,
        name: '',
        current: null
      }
      this.creating_checklist_item = {
        mr_checklist_id: cl.id,
        status: true,
        name: ''
      }
    },
    switchIndexChecklistItem (checklist, j, type = 'down') {
      let items = checklist.items
      if (type === 'up') {
        if (j > 0) {
          let b = items[j]
          items[j] = items[j - 1]
          items[j - 1] = b
        }
      } else {
        if (j < items.length - 1) {
          let b = items[j]
          items[j] = items[j + 1]
          items[j + 1] = b
        }
      }
      console.log(items)
      this.multipleUpdateChecklistItemOrdNum(items)
    },
    async postChecklistItem (index) {
      let idx = index || 0
      let params = {
        mr_checklist_id: this.creating_checklist_item.mr_checklist_id,
        name: this.creating_checklist_item.name,
        ordinal_num: idx + 2
      }
      this.loading = true
      this.$rf.getRequest('AdminRequest').postChecklistItem(params).then((r) => {
        this.getCheckpoints()
        this.creating_checklist_item = {
          mr_checkpoint_id: 0,
          status: false,
          name: ''
        }
      }).finally(() => {
        this.loading = false
      })
    },
    async updateChecklistItem (stt) {
      let params = {
        mr_checklist_id: stt.mr_checklist_id,
        name: this.editting_checklist_item.name,
        ordinal_num: stt.ordinal_num
      }
      this.changing_index = true
      this.$rf.getRequest('AdminRequest').updateChecklistItem(stt.id, params).then((r) => {
        this.getCheckpoints()
        this.editting_checklist_item = {
          id: 0,
          status: false,
          name: '',
          current: null
        }
      }).finally(() => {
        this.changing_index = false
      })
    },
    async multipleUpdateChecklistItemOrdNum (stts) {
      console.log(stts)
      this.changing_index = true
      let items = []
      let i = 0
      stts.forEach((stt) => {
        i++
        items.push({
          id: stt.id,
          mr_checklist_id: stt.mr_checklist_id,
          name: stt.name,
          ordinal_num: i
        })
      })
      let r = this.$rf.getRequest('AdminRequest').multipleUpdateChecklistItem({ items: items }).finally(() => {
        this.getCheckpoints()
        this.changing_index = false
      })
      return r
    }
    // checklist item
  }
}
</script>

<style scoped>
.switch-index:hover {
  background-color: rgb(214, 214, 214);
}
.switch-index.disabled,
a.disabled {
  pointer-events: none;
  opacity: 0.2;
}
.cl-items:hover {
  background-color: rgb(240, 239, 239);
}
</style>